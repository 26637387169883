<template>
  <div class="login-wrap">
      <!-- <fixed-header
        :isHideBack="false"
        :isEmit="true"
        @handleHeadide="handleHide"
        @handleClick="handleHide"
      ></fixed-header> -->
      <div class="login_banner">
        <img
          src="@/assets/images/login-topimg.png"
        />
      </div>
      <ul class="title-ul">
        <li data-type="tel" :class="{ active: isActive == 0 }" @click="handleTab(0)">
          快速登录
        </li>
        <li
          data-type="account"
          :class="{ active: isActive == 1 }"
          @click="handleTab(1)"
        >
          账密登录
        </li>
      </ul>
      <div class="form-wapper">
        <div class="form" v-show="isActive == 0">
          <div class="form-item">
            <label for="tel">
              <div class="form-label">
                <input
                  placeholder="请输入手机号"
                  id="tel"
                  ref="tel"
                  v-model="telphone"
                  name="tel"
                  type="text"
                />
              </div>
            </label>
        </div>

        <div class="form-item code-item code-pic">
            <label for="code">
              <div class="form-label code-form-label">
                <input
                    placeholder="请输入验证码"
                    id="passCode"
                    v-model="passCode"
                    name="passCode"
                    maxlength="4"
                />
              </div>
            </label>
          <img :src="codeImg" @click="getCodeImg" alt />
          </div>

          <div class="form-item code-item">
            <label for="code">
              <div class="form-label code-form-label">
                <input
                  placeholder="请输入验证码"
                  id="code"
                  v-model="code"
                  name="code"
                  type="number"
                  maxlength="6"
                  @input="changeNum"
                />
              </div>
            </label>
            <button
              id="getCode"
              @click="getSMSCode"
              v-if="codeText == '获取验证码'"
            >
              {{ codeText }}
            </button>
            <button id="getCode" v-else>{{ codeText }}</button>
          </div>
        </div>
        <div class="form" v-show="isActive == 1">
          <div class="form-item">
            <label for="account">
              <div class="form-label">
                <input
                  placeholder="请输入手机号"
                  id="account"
                  ref="tel"
                  v-model="telphone"
                  name="tel"
                  type="text"
                />
              </div>
            </label>
          </div>
          <div class="form-item">
            <label for="password">
              <div class="form-label">
                <input
                  placeholder="请输入密码"
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                />
              </div>
            </label>
          </div>
        </div>
        <div :class="{ 'submit-wapper': true, 'submit-weixin': isweixin }">
          <div
            id="code-login"
            :class="{ submit: true, 'weixin-login': isweixin }"
            @click="handleCodeLogin"
            v-show="isActive == 0"
          >
            立即登录
          </div>
           <div
            id="weixin-login"
            :class="{ submit: true, 'weixin-login': isweixin }"
            style="background: #44b549"
            v-if="isweixin && isActive == 0"
            @click="weixinLogin"
          >
            微信一键登录
          </div>
        </div>
        <div
          id="pass-login"
          class="submit"
          @click="handlePassLogin"
          v-show="isActive == 1"
        >
          立即登录
        </div>
        <router-link :to="{ name: 'Forget' }" class="forget-password">忘记密码</router-link>
      </div>
    </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import fixedHeader from "@/components/common/Header.vue";
import Toast from "@/components/UI/Toast";
import api from "@/api/axios";
import { useRouter, useRoute } from "vue-router";


export default defineComponent({
  name: "Login",
//   components: {
//     fixedHeader,
//   },
  emits: ["loginsuccess"],
  setup(props, ctx) {
    // const hide = inject("handleHide");

    // function handleHide() {
    //   (hide as () => void)();
    // }
    const router = useRouter();
    const route = useRoute();
    const isActive = ref(0);
    function handleTab(type: number) {
      isActive.value = type;
    }

    const isweixin = ref(false);
    const ua = navigator.userAgent.toLowerCase();
    const reg = /MicroMessenger/i;
    isweixin.value = reg.test(ua);

    //code 登录
    const telphone = ref<string>("");
    const code = ref<string>("");
    const password = ref<string>("");
    const passCode=ref<string>("");
    const sendCodeFlag = ref<boolean>(true);
    const codeText = ref<string>("获取验证码");
    let timer: any = ref(null);
    const count = ref(60);

    function checkTel(tel: string) {
      if (tel === "") {
        Toast({
          title: "请输入手机号码",
          type: "warning",
        });
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (tel.length != 11 || !retel.test(tel)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        // $('#tel').focus();
        return false;
      }
      return true;
    }

    //获取验证码
    function getSMSCode() {
      if (!checkTel(telphone.value)) {
        return false;
      }
      
      if( !sendCodeFlag.value ){
         return false
      }
      sendCodeFlag.value = false;
      /**
       *  获取验证码
       * */
      const formData = new FormData();
      formData.append("tel", telphone.value);
      formData.append("option", "getcode");
      api
        .post("/M/Server/GetNewsSMSCode", formData)
        .then((res) => {
          if (res.data.success) {
            Toast({
              title: "验证码发送成功，请注意查收",
              type: "success",
            });
             count.value = 60;
                codeText.value = count.value + "s";
                timer = setInterval(function () {
                  count.value--;
                  if (count.value == 0) {
                    clearInterval(timer);
                    sendCodeFlag.value = true;
                    codeText.value = "获取验证码";
                  } else {
                    codeText.value = count.value + "s";
                  }

                }, 1000);
          } else {
            sendCodeFlag.value = true;
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //     title: '未知错误，请联系客服',
          //     type: "success"
          // })
        });
      // count.value = 60;
      // codeText.value = count.value + "s";
      // timer = setInterval(function () {
      //    count.value--;
      //   if (count.value == 0) {
      //     clearInterval(timer);
      //     sendCodeFlag.value = true;
      //     codeText.value = "获取验证码";
      //   } else {
      //     codeText.value = count.value + "s";
      //   }

      // }, 1000);
    }

    //  登录成功处理
    const UPDATE_USERINFO = inject("UPDATE_USERINFO") as (data: object) => void;
    function updateToken(token: string) {
      localStorage.setItem("token", token);
    }

    function handleUserInfo(data: object) {
      UPDATE_USERINFO(data);
    }
    const codeImg = ref("/M/Server/GetVerifyCodeImg");
      function getCodeImg() {
        codeImg.value = "/M/Server/GetVerifyCodeImg?random=" + Math.random();
      }


    //验证码登录
    function handleCodeLogin() {
      if (!passCode.value) {
          Toast({
            title: "请输入图形验证码",
            type: "warning",
          });
          return false;
        }
      const formData = new FormData();
      formData.append("username", telphone.value);
      formData.append("smscode", code.value);
      formData.append("passcode", passCode.value);
      api
        .post("/M/Server/DoLoginWidthCode", formData)
        .then((res) => {
          if (res.data.success) {
            updateToken(res.data.token);
            handleUserInfo(res.data.obj);
            //  清除购物车的guid
            //  localStorage.removeItem('guid')
            Toast({
              title: "登录成功！",
              type: "success",
            });


            if (String(res.data.msg).trim() == "请设置密码!") {
              router.push({ name: "SetPassword" })
              sessionStorage.setItem("telphone", telphone.value)
              return false;
            } else {
              // handleHide();
              ctx.emit("loginsuccess");
            }
            const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            router.push(redirect)
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
        });
    }



    //密码登录
    function handlePassLogin() {
      const formData = new FormData();
      formData.append("username", telphone.value);
      formData.append("password", password.value);
      api
        .post("/M/Server/DoLoginWidthPassword", formData)
        .then((res) => {
          if (res.data.success) {
            updateToken(res.data.token);
            handleUserInfo(res.data.obj);
             //  清除购物车的guid
             //localStorage.removeItem('guid')
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                // handleHide();
                ctx.emit("loginsuccess");
              },
            });
            const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            router.push(redirect)
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
          
        });
    }

    function changeNum() {
      if(code.value.length > 6){
        code.value = code.value.slice(0, 6)
      }
    }


    function weixinLogin(){
      const fData = new FormData();
      const redirect = (route.query.redirect ? route.query.redirect : "/mine") as string;
      fData.append("returnurl", window.location.origin + redirect);
      api
        .post("/M/User/WeixinLogin", fData)
        .then((res) => {
          if (res.data.success) {
            window.location.href = res.data.returnUrl;
            // api.get(res.data.returnUrl).then(d => {
            //   const data = d.data.obj;
            //   if(data.token){
            //     updateToken(data.token);
            //     handleUserInfo(data.obj);
            //     Toast({
            //       type: "success",
            //       title: res.data.msg,
            //       onClose: () => {
            //         ctx.emit("loginsuccess");
            //       },
            //     });
            //     const redirect = (route.query.redirect ? route.query.redirect : "/") as string;
            //     router.push(redirect)
            //   }else{
            //     if(data.unionid){
            //       router.push({ name: "InitTel" })
            //     }else{
            //       Toast({
            //         type: "warning",
            //         title: "请先关注普天同创公众号"
            //       });
            //     }
            //   }
            // })
          } else {
            Toast({
              title: res.data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          // Toast({
          //   title: "未知错误，请联系客服",
          //   type: "success",
          // });
        });
    }

    return {
    //   handleHide,
      isActive,
      handleTab,
      isweixin,
      telphone,
      code,
      password,
      codeText,
      getSMSCode,
      handleCodeLogin,
      handlePassLogin,
      changeNum,
      weixinLogin,
        codeImg,
        getCodeImg,
        passCode
    };
  },
});
</script>

<style lang="scss" scoped>
.login_banner img {
  width: 10rem;
}

.title-ul {
  overflow: hidden;
}

.title-ul li {
  float: left;
  position: relative;
  width: 50%;
  height: 0.867rem;
  line-height: 0.867rem;
  text-align: center;
  color: #999999;
  font-size: 0.4rem;
}

.title-ul .active {
  color: #444;
}

.title-ul .active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0.267rem;
  height: 0.04rem;
  background-color: #b9135a;
  margin-left: -0.133rem;
  opacity: 1;
}

.form-wapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.267rem;
  padding-top: 0.773rem;
}

.form-label {
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  background-color: #f4f4f4;
  border-radius: 0.067rem;
  padding-left: 0.4rem;
  box-sizing: border-box;
  margin-bottom: 0.587rem;
}

.form-label input {
  outline: none;
  border: none;
  width: 100%;
  height: 1.2rem;
  line-height: 1.2rem;
  vertical-align: top;
  background-color: transparent;
}
.code-item {
  position: relative;
  padding-right: 3.467rem;
  box-sizing: border-box;
}
.code-item button {
  position: absolute;
  right: 0;
  top: 0.32rem;
  width: 2.4rem;
  height: 0.8rem;
  border-radius: 0.067rem;
  border: solid 0.027rem #b9135a;
  line-height: 0.8rem;
  text-align: center;
  color: #b9135a;
  font-size: 0.373rem;
  background: none;
  outline: none;
  display: block;
}
 .code-pic {
    position: relative;
    padding-right: 3.467rem;
    box-sizing: border-box;
  }

  .code-pic img {
    position: absolute;
    right: 0;
    top: 0.32rem;
    width: 2.4rem;
    height: 0.8rem;
    border-radius: 0.067rem;
    // border: solid 0.027rem #d64b4b;
    line-height: 0.8rem;
    text-align: center;
    color: #b9135a;
    font-size: 0.373rem;
    background: none;
    outline: none;
    display: block;
  }


.submit {
  margin-top: 0.987rem;

  display: block;
  width: 100%;
  height: 1.2rem;
  background-color: #b9135a;
  box-shadow: 0 0.133rem 0.267rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.133rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
  margin-top: 1rem;
}

.forget-password {
  margin-top: 0.267rem;
  color: #b9135a;
  float: right;
  margin-left: 0.533rem;
  font-size: 0.32rem;
}
.login-wrap {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  height: 100%;
  width: 100%;
  padding-top: 55px;
}
.login-transform {
  transform: translateX(0);
}

.weixin-login {
  width: 45%;
  //  width: 100%;
}
.submit-weixin {
  display: flex;
  justify-content: space-between;
}

.login-enter-active,
.login-leave-active {
  transform: translateX(0);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.login-enter-from,
.login-leave-to {
  transform: translateX(100%);
}
</style>
